import React from "react"
import Layout from "../components/default-layout"
import { Container, Grid } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"

const Galeria = () => {

  return (
    <Layout>
      <Container>
        <Grid
          gap={[2, 3]}
          sx={{
            gridTemplateColumns: [
              `repeat(2, minmax(100px, 1fr))`,
              `repeat(2, minmax(100px, 1fr))`,
              `repeat(2, minmax(200px, 1fr))`,
              `repeat(4, minmax(250px, 1fr))`,
              `repeat(4, minmax(250px, 1fr))`,
              `repeat(4, minmax(250px, 1fr))`
            ],
            marginLeft: `auto`,
            marginRight: `auto`,
            marginTop: 6,
            backgroundColor: `primary`,
            padding: 3,
          }}
        >
          <StaticImage
            src="../images/jornadas-libres-de-violencias-01.jpg"
            alt=""
            layout="fullWidth"
          />
          <StaticImage
            src="../images/jornadas-libres-de-violencias-02.jpg"
            alt=""
            layout="fullWidth"
          />
          <StaticImage
            src="../images/jornadas-libres-de-violencias-05.jpg"
            alt=""
            layout="fullWidth"
          />
          <StaticImage
            src="../images/jornadas-libres-de-violencias-06.jpg"
            alt=""
            layout="fullWidth"
          />
          <StaticImage
            src="../images/jornadas-libres-de-violencias-07.jpg"
            alt=""
            layout="fullWidth"
          />
          <StaticImage
            src="../images/jornadas-libres-de-violencias-09.jpg"
            alt=""
            layout="fullWidth"
          />
          <StaticImage
            src="../images/jornadas-libres-de-violencias-10.jpg"
            alt=""
            layout="fullWidth"
          />
          <StaticImage
            src="../images/jornadas-libres-de-violencias-12.jpg"
            alt=""
            layout="fullWidth"
          />
          <StaticImage
            src="../images/jornadas-libres-de-violencias-13.jpg"
            alt=""
            layout="fullWidth"
          />
            <StaticImage
            src="../images/jornadas-libres-de-violencias-03.jpg"
            alt=""
            layout="fullWidth"
          />
          <StaticImage
            src="../images/jornadas-libres-de-violencias-11.jpg"
            alt=""
            layout="fullWidth"
          />
          <StaticImage
            src="../images/cartel.jpg"
            alt=""
            layout="fullWidth"
          />
        </Grid>
      </Container>

    </Layout>
  )

}

export default Galeria